var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items.values)?_c('v-card',{staticClass:"cont-table"},[_c('v-card-title',[_c('h3',[_vm._v("All Anchors Aggregate Text")])]),_c('div',{staticClass:"col-7 margin-auto"},[_c('pie-chart-donut',{attrs:{"series":_vm.getCategories(),"displays":['Exact Match', 'Partial Match', 'Branded', 'Naked URL', 'Generic']}})],1),_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.anchors,"loading":_vm.loading,"search":_vm.search,"item-key":"url","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.anchor",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"col-domain"},[_vm._v(" "+_vm._s(item.anchor)+" ")])]}},{key:"item.count",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Number(item.count).toFixed(0))+" ")]}},{key:"item.percentage",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Number(item.percentage).toFixed(2))+"% ")]}},{key:"item.chart",fn:function(ref){
var item = ref.item;
return [_c('div',{style:(("background: #ff8800; width: " + (Number(item.chart).toFixed(2)) + "%; height: 10px;"))})]}}],null,true)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }