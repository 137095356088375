<template>
  <v-card class="cont-table">
    <v-card-title>
      <h3>URL link profile</h3>
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
    </v-card-title>

    <v-data-table
      class="elevation-1"
      :headers="headers"
      :items="items"
      :loading="loading"
      :search="search"
      item-key="url"
      show-select
      v-model="selected"
      :single-select="singleSelect"
      mobile-breakpoint="0"
    >
      <template slot="body.prepend">
        <tr v-for="(comp, index) in comparisons" :key="index">
          <td></td>
          <td>
            <div class="col-domain">
              <a :href="comp.url" target="_blank">{{ comp.url }}</a>
            </div>
          </td>
          <td>
            <span :class="getTextClass('page_inbound_links', index)">
              {{ Number(comp.page_inbound_links).toFixed(0) }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('page_followed_links', index)">
              {{ Number(comp.page_followed_links).toFixed(0) }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('page_ref_domains', index)">{{
              Number(comp.page_ref_domains).toFixed(0)
            }}</span>
          </td>
          <td>
            <span :class="getTextClass('page_ref_domains_by_total_links', index)">
              {{ Number(comp.page_ref_domains_by_total_links * 100).toFixed(2) }}%
            </span>
          </td>
          <td>
            <span :class="getTextClass('page_follow_by_total_links', index)">
              {{ Number(comp.page_follow_by_total_links * 100).toFixed(2) }}%
            </span>
          </td>
          <td>
            <span :class="getTextClass('page_avg_domain_rating', index)">
              {{ Number(comp.page_avg_domain_rating * 100).toFixed(2) }}
            </span>
          </td>
        </tr>
        <tr>
          <td></td>
          <td>Averages</td>
          <td>{{ Number(averages.page_inbound_links).toFixed(0) }}</td>
          <td>{{ Number(averages.page_followed_links).toFixed(0) }}</td>
          <td>{{ Number(averages.page_ref_domains).toFixed(0) }}</td>
          <td>{{ Number(averages.page_ref_domains_by_total_links * 100).toFixed(2) }}%</td>
          <td>{{ Number(averages.page_follow_by_total_links * 100).toFixed(2) }}%</td>
          <td>{{ Number(averages.page_avg_domain_rating * 100).toFixed(2) }}</td>
        </tr>
      </template>

      <template v-slot:[`item.url`]="{ item }">
        <div class="col-domain">
          <a :href="item.url" target="_blank">{{ item.url }}</a>
        </div>
      </template>
      <template v-slot:[`item.page_inbound_links`]="{ item }">
        {{ Number(item.page_inbound_links).toFixed(0) }}
      </template>
      <template v-slot:[`item.page_followed_links`]="{ item }">
        {{ Number(item.page_followed_links).toFixed(0) }}
      </template>
      <template v-slot:[`item.page_ref_domains`]="{ item }">
        {{ Number(item.page_ref_domains).toFixed(0) }}
      </template>
      <template v-slot:[`item.page_ref_domains_by_total_links`]="{ item }">
        {{ Number(item.page_ref_domains_by_total_links * 100).toFixed(2) }}%
      </template>
      <template v-slot:[`item.page_follow_by_total_links`]="{ item }">
        {{ Number(item.page_follow_by_total_links * 100).toFixed(2) }}%
      </template>
      <template v-slot:[`item.page_avg_domain_rating`]="{ item }">
        {{ Number(item.page_follow_by_total_links).toFixed(2) }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  props: {
    loading: Boolean,
    items: Array,
    comparisons: Array,
    avgs: Object,
  },
  data() {
    return {
      headers: [
        { text: 'URL', value: 'url', sortable: true, width: 350 },
        { text: 'Inbound Links', value: 'page_inbound_links', sortable: true },
        { text: 'Followed', value: 'page_followed_links', sortable: true },
        { text: 'Total Domains', value: 'page_ref_domains', sortable: true },
        { text: 'Domains/Links', value: 'page_ref_domains_by_total_links', sortable: true },
        { text: 'Followed/Links', value: 'page_follow_by_total_links', sortable: true },
        { text: 'Avg Domain Rating', value: 'page_avg_domain_rating', sortable: true },
      ],
      search: '',
      singleSelect: false,
      selected: this.items ? this.items : [],
      averages: this.avgs ? this.avgs : [],
    }
  },
  watch: {
    selected: {
      handler() {
        this.getAverages()
      },
    },
  },
  methods: {
    getAverages() {
      let totals = this.averages
      const size = this.selected.length

      for (const prop_name in this.averages) {
        totals[prop_name] = 0
      }

      this.selected.forEach(row => {
        for (const prop_name in row) {
          totals[prop_name] += Number(row[prop_name])
        }
      })

      for (const prop_name in totals) {
        totals[prop_name] = totals[prop_name] / size
      }

      this.averages = totals
    },
    getTextClass(name = '', index = 0, greater = false) {
      if(greater === false){
        if (this.comparisons[index][name] >= this.averages[name]) {
          return 'greater-than';
        } else {
          return 'less-than';
        }
      }else{
        if (this.comparisons[index][name] <= this.averages[name]) {
          return 'greater-than';
        } else {
          return 'less-than';
        }
      }
    },
  },
}
</script>

<style scoped>
.greater-than {
  color: #2ecc71;
}
.less-than {
  color: #ff4c51;
}
.cont-table {
  display: flex;
  flex-direction: column;
  margin: 30px 0px;
  gap: 20px;
}
.col-domain {
  max-width: 500px !important;
  word-break: break-all;
}
</style>
