<template>
    <v-card class="cont-table">
      <v-card-title>
        <h3>Speed/Core Web Vitals</h3>
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
      </v-card-title>

      <v-data-table
        class="elevation-1"
        :headers="headers"
        :items="items"
        :loading="loading"
        :search="search"
        item-key="url"
        show-select
        v-model="selected"
        :single-select="singleSelect"
        mobile-breakpoint="0"
      >

        <template slot="body.prepend">
        <tr v-for="(comp, index) in comparisons" :key="index">
          <td></td>
          <td> {{ 'comparison' }} </td>
          <td>
            <div class="col-domain">
              <a :href="comp.url" target="_blank">{{ comp.url }}</a>
            </div>
          </td>
          <td>
            <span :class="getTextClass('backlinks', index)">
              {{ comp.url_rank_serp }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('text', index)">
              {{ comp.mobile_speed }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('image', index)">
              {{ comp.mobile_contentful_paint }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('ugc', index)">
              {{ comp.mobile_max_first_input_delay }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('sponsored', index)">
              {{ comp.mobile_cumulative_layout }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('redirect', index)">
              {{ comp.desktop_speed }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('redirect', index)">
              {{ comp.desktop_contentful_paint }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('redirect', index)">
              {{ comp.desktop_max_first_input_delay }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('redirect', index)">
              {{ comp.desktop_layout_shift }}
            </span>
          </td>
        </tr>
      </template>

        <template v-slot:[`item.anchor`]="{ item }">
          <div class="col-domain">
            {{ item.anchor }}
          </div>
        </template>
        <template v-slot:[`item.count`]="{ item }">
          {{ Number(item.count).toFixed(0) }}
        </template>
        <template v-slot:[`item.percentage`]="{ item }"> {{ Number(item.percentage).toFixed(2) }}% </template>
        <template v-slot:[`item.chart`]="{ item }">
          <div :style="`background: #ff8800; width: ${Number(item.chart).toFixed(2)}%; height: 10px;`"></div>
        </template>

        <template slot="body.append">
          <tr>
          <td></td>
          <td>Averages</td>
          <td>{{ averages.url_rank_serp }}</td>
          <td>{{ averages.mobile_speed }}</td>
          <td>{{ averages.mobile_contentful_paint }}</td>
          <td>{{ averages.mobile_max_first_input_delay }}</td>
          <td>{{ averages.mobile_cumulative_layout }}</td>
          <td>{{ averages.desktop_speed }}</td>
          <td>{{ averages.desktop_contentful_paint }}</td>
          <td>{{ averages.desktop_max_first_input_delay }}</td>
          <td>{{ averages.desktop_layout_shift }}</td>
          </tr>
        </template>

      </v-data-table>
    </v-card>
</template>

<script>
export default {
  props: {
    loading: Boolean,
    items: Array,
    comparisons: Array,
  },
  data() {
    return {
      headers: [
        {
          text: 'URL',
          value: 'url',
          sortable: true,
          width: 300,
        },
        { text: 'URL Rank in the SERP', value: 'url_rank_serp', sortable: true },
        { text: 'Mobile Speed (Speed Index)', value: 'mobile_speed', sortable: true },
        { text: 'Mobile Largest Contentful Paint', value: 'mobile_contentful_paint', sortable: true },
        { text: 'Mobile Max First Input Delay', value: 'mobile_max_first_input_delay', sortable: true },
        { text: 'Mobile Cumulative Layout Shift', value: 'mobile_cumulative_layout', sortable: true },
        { text: 'Desktop Speed (Speed Index)', value: 'desktop_speed', sortable: true },
        { text: 'Desktop Largest Contentful Paint', value: 'desktop_contentful_paint', sortable: true },
        { text: 'Desktop Max First Input Delay', value: 'desktop_max_first_input_delay', sortable: true },
        { text: 'Deskop Cumulative Layout Shift', value: 'desktop_layout_shift', sortable: true },
      ],
      search: '',
      singleSelect: false,
      selected: this.items ? this.items : [],
    }
  },
  computed: {
    averages: function () {
      return this.getAverages()
    }
  },
  methods: {
    getAverages() {
      let totals = [];
      const size = this.selected.length

      this.headers.forEach(row => {
        totals[row.value] = 0;
      });

      this.selected.forEach(row => {
        for (const prop_name in row) {
          totals[prop_name] += Number(row[prop_name])
        }
      })

      for (const prop_name in totals) {
        totals[prop_name] = Number(totals[prop_name] / size).toFixed(2)
      }

      return totals;

      // this.averages = totals;
    },
    getTextClass(name = '', index = 0, greater = false) {
      if (greater === false) {
        if (this.comparisons[index][name] >= this.averages[name]) {
          return 'greater-than'
        } else {
          return 'less-than'
        }
      } else {
        if (this.comparisons[index][name] <= this.averages[name]) {
          return 'greater-than'
        } else {
          return 'less-than'
        }
      }
    },
  },
}
</script>

<style>
</style>
