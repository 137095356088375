<template>
  <div id="chart">
    <apexchart type="donut" :options="chartOptions" :series="series" ></apexchart>
  </div>
</template>

<script>
export default {
  props: ['series','displays'],
  data() {
    return {
      //series:  [10, 41, 35, 51,65],
      chartOptions: {
        labels: this.displays,
        chart: {
          type: 'donut',
        },
        responsive: [
          {
            breakpoint: 20,
            options: {
              chart: {
                width: 200,
                redrawOnParentResize: true,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
        ],
      },
    }
  },
}
</script>

<style>
</style>