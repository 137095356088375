<template>
  <v-card class="cont-table">
    <v-card-title>
      <h3>Action Items to Consider</h3>
    </v-card-title>
    <v-expansion-panels accordion>
      <v-expansion-panel v-for="(comp, index) in items" :key="index">
        <v-expansion-panel-header>
          <div class="item-option">
            <span color="info" size="20">
              <v-chip class="ma-2" color="secondary" outlined>{{ 'comparison' }} </v-chip>
            </span>
            <span class="item-title">{{ comp.url }}</span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card>
            <v-card-title>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              class="elevation-1"
              :headers="headers"
              :items="comp.items"
              :loading="loading"
              :search="search"
              item-key="url"
              mobile-breakpoint="0"
            >
              <template v-slot:[`item.table`]="{ item }">
                {{ item.table }}
              </template>
              <template v-slot:[`item.metric`]="{ item }">
                {{ item.metric }}
              </template>
              <template v-slot:[`item.value`]="{ item }">
                <sapn class="text-error">{{ item.value }}</sapn>
              </template>
              <template v-slot:[`item.message`]="{ item }">
                {{ item.message }}
              </template>
            </v-data-table>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import AnchorTextItem from './AnchorTextItem.vue'
export default {
  components: { AnchorTextItem },
  props: {
    loading: Boolean,
    items: Array,
  },
  data() {
    return {
      headers: [
        { text: 'Table', value: 'table', sortable: true, width: 200 },
        { text: 'Metric', value: 'metric', sortable: true },
        { text: 'Current Value', value: 'value', sortable: true },
        { text: 'Message', value: 'message', sortable: true },
      ],
      search: '',
    }
  },
  methods: {},
}
</script>

<style scoped>
.text-error {
  color: #ff4c51 !important;
}
.item-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  word-break: break-all;
}
.item-title {
  color: #2ecc71;
}
.cont-table {
  display: flex;
  flex-direction: column;
  margin: 30px 0px;
  gap: 20px;
}
</style>
