<template>
    <v-card class="cont-table">
    <v-card-title>
      <h3>UR and DR</h3>
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
    </v-card-title>

    <v-data-table
      class="elevation-1"
      :headers="headers"
      :items="items"
      :loading="loading"
      :search="search"
      item-key="url"
      show-select
      v-model="selected"
      :single-select="singleSelect"
      mobile-breakpoint="0"
    >
      <template slot="body.prepend">
        <tr v-for="(comp, index) in comparisons" :key="index">
          <td></td>
          <td>
            <div class="col-domain">
              <a :href="comp.url" target="_blank">{{ comp.url }}</a>
            </div>
          </td>
          <td>
            <span :class="getTextClass('avg_ur', index)">
              {{ Number(comp.avg_ur).toFixed(2) }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('avg_dr', index)">
              {{ Number(comp.avg_dr).toFixed(2) }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('max_ur', index)">
              {{ Number(comp.max_ur).toFixed(2) }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('max_dr', index)">
              {{ Number(comp.max_dr).toFixed(2) }}
            </span>
          </td>
        </tr>
        <tr>
          <td></td>
          <td>Averages</td>
          <td>{{ Number(averages.avg_ur).toFixed(2) }}</td>
          <td>{{ Number(averages.avg_dr).toFixed(2) }}</td>
          <td>{{ Number(averages.max_ur).toFixed(2) }}</td>
          <td>{{ Number(averages.max_dr).toFixed(2) }}</td>
        </tr>
      </template>

      <template v-slot:[`item.url`]="{ item }">
        <div class="col-domain">
          <a :href="item.url" target="_blank">{{ item.url }}</a>
        </div>
      </template>
      <template v-slot:[`item.backlinks`]="{ item }">
        {{ Number(item.avg_ur).toFixed(2) }}
      </template>
      <template v-slot:[`item.text`]="{ item }">
        {{ Number(item.avg_dr).toFixed(2) }}
      </template>
      <template v-slot:[`item.image`]="{ item }">
        {{ Number(item.max_ur).toFixed(2) }}
      </template>
      <template v-slot:[`item.ugc`]="{ item }">
        {{ Number(item.max_dr).toFixed(2) }}
      </template>

    </v-data-table>
  </v-card>

</template>

<script>
export default {
  props: {
    loading: Boolean,
    items: Array,
    comparisons: Array,
    avgs: Object,
  },
  data() {
    return {
      headers: [
        { text: 'URL', value: 'url', sortable: true, width: 350 },
        { text: 'Average UR', value: 'avg_ur', sortable: true },
        { text: 'Average DR', value: 'avg_dr', sortable: true },
        { text: 'Max UR', value: 'max_ur', sortable: true },
        { text: 'Max DR', value: 'max_dr', sortable: true },
      ],
      search: '',
      singleSelect: false,
      selected: this.items ? this.items : [],
      averages: this.avgs ? this.avgs : [],
    }
  },
  watch: {
    selected: {
      handler() {
        this.getAverages()
      },
    },
  },
  methods: {
    getAverages() {
      let totals = this.averages
      const size = this.selected.length

      for (const prop_name in this.averages) {
        totals[prop_name] = 0
      }

      this.selected.forEach(row => {
        for (const prop_name in row) {
          totals[prop_name] += Number(row[prop_name])
        }
      })

      for (const prop_name in totals) {
        totals[prop_name] = totals[prop_name] / size
      }

      this.averages = totals
    },
    getTextClass(name = '', index = 0, greater = false) {
      if(greater === false){
        if (this.comparisons[index][name] >= this.averages[name]) {
          return 'greater-than';
        } else {
          return 'less-than';
        }
      }else{
        if (this.comparisons[index][name] <= this.averages[name]) {
          return 'greater-than';
        } else {
          return 'less-than';
        }
      }
    },
  },
}
</script>

<style scoped>
.greater-than {
  color: #2ecc71;
}
.less-than {
  color: #ff4c51;
}
.cont-table {
  display: flex;
  flex-direction: column;
  margin: 30px 0px;
  gap: 20px;
}
.col-domain {
  max-width: 600px !important;
  word-break: break-all;
}
</style>
