<template>
  <v-card class="cont-table">
    <v-card-title>
      <h3>Backlinks Seen</h3>
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
    </v-card-title>

    <v-data-table
      class="elevation-1"
      :headers="headers"
      :items="items"
      :loading="loading"
      :search="search"
      item-key="url"
      show-select
      v-model="selected"
      :single-select="singleSelect"
      mobile-breakpoint="0"
    >
      <template slot="body.prepend">
        <tr v-for="(comp, index) in comparisons" :key="index">
          <td></td>
          <td> {{ 'comparison' }} </td>
          <td>
            <div class="col-domain">
              <a :href="comp.url" target="_blank">{{ comp.url }}</a>
            </div>
          </td>
          <td>
            <span :class="getTextClass('last_week', index)">
              {{ Number(comp.last_week) }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('last_month', index)">
              {{ Number(comp.last_month)}}
            </span>
          </td>
          <td>
            <span :class="getTextClass('last_3_months', index)">
              {{Number(comp.last_3_months)}} </span>
          </td>
          <td>
            <span :class="getTextClass('last_6_months', index)"
              >{{ Number(comp.last_6_months ) }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('last_12_months', index)"
              >{{ Number(comp.last_12_months ) }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('months_ago_1', index)"
              >{{ Number(comp.months_ago_1 ) }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('months_ago_2', index)"
              >{{ Number(comp.months_ago_2 ) }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('months_ago_3', index)"
              >{{ Number(comp.months_ago_3 ) }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('months_ago_4', index)"
              >{{ Number(comp.months_ago_4 ) }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('months_ago_5', index)"
              >{{ Number(comp.months_ago_5 ) }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('months_ago_6', index)"
              >{{ Number(comp.months_ago_6 ) }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('months_ago_7', index)"
              >{{ Number(comp.months_ago_7 ) }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('months_ago_8', index)"
              >{{ Number(comp.months_ago_8 ) }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('months_ago_9', index)"
              >{{ Number(comp.months_ago_9 ) }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('months_ago_10', index)"
              >{{ Number(comp.months_ago_10 ) }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('months_ago_11', index)"
              >{{ Number(comp.months_ago_11 ) }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('months_ago_12', index)"
              >{{ Number(comp.months_ago_12 ) }}
            </span>
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td>Averages</td>
          <td>{{ Number(averages.last_week).toFixed(2) }}</td>
          <td>{{ Number(averages.last_month).toFixed(2) }}</td>
          <td>{{ Number(averages.last_3_months).toFixed(2) }}</td>
          <td>{{ Number(averages.last_6_months).toFixed(2) }}</td>
          <td>{{ Number(averages.last_12_months).toFixed(2) }}</td>
          <td>{{ Number(averages.months_ago_1).toFixed(2) }}</td>
          <td>{{ Number(averages.months_ago_2).toFixed(2) }}</td>
          <td>{{ Number(averages.months_ago_3).toFixed(2) }}</td>
          <td>{{ Number(averages.months_ago_4).toFixed(2) }}</td>
          <td>{{ Number(averages.months_ago_5).toFixed(2) }}</td>
          <td>{{ Number(averages.months_ago_6).toFixed(2) }}</td>
          <td>{{ Number(averages.months_ago_7).toFixed(2) }}</td>
          <td>{{ Number(averages.months_ago_8).toFixed(2) }}</td>
          <td>{{ Number(averages.months_ago_9).toFixed(2) }}</td>
          <td>{{ Number(averages.months_ago_10).toFixed(2) }}</td>
          <td>{{ Number(averages.months_ago_11).toFixed(2) }}</td>
          <td>{{ Number(averages.months_ago_12).toFixed(2) }}</td>
        </tr>
      </template>

      <template v-slot:[`item.url`]="{ item }">
        <div class="col-domain">
          <a :href="item.url" target="_blank">{{ item.url }}</a>
        </div>
      </template>
      <template v-slot:[`item.domain_inbound_links`]="{ item }">
        {{ Number(item.domain_inbound_links).toFixed(0) }}
      </template>
      <template v-slot:[`item.domain_followed_links`]="{ item }">
        {{ Number(item.domain_followed_links).toFixed(0) }}
      </template>
      <template v-slot:[`item.domain_ref_domains`]="{ item }">
        {{ Number(item.domain_ref_domains).toFixed(0) }}
      </template>
      <template v-slot:[`item.domain_ref_domains_by_total_links`]="{ item }">
        {{ Number(item.domain_ref_domains_by_total_links * 100).toFixed(2) }}%
      </template>
      <template v-slot:[`item.domain_follow_by_total_links`]="{ item }">
        {{ Number(item.domain_follow_by_total_links * 100).toFixed(2) }}%
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  props: {
    loading: Boolean,
    items: Array,
    comparisons: Array,
    avgs: Object,
  },
  data() {
    return {
      headers: [
        { text: 'Position', value: 'position', sortable: true },
        { text: 'URL', value: 'url', sortable: true, width: 350 },
        { text: 'Last Week', value: 'last_week', sortable: true, width: 100 },
        { text: 'Last Month', value: 'last_month', sortable: true, width: 100 },
        { text: 'Last 3 Months', value: 'last_3_months', sortable: true, width: 100 },
        { text: 'Last 6 Months', value: 'last_6_months', sortable: true, width: 100 },
        { text: 'Last 12 Month', value: 'last_12_months', sortable: true, width: 100 },
        { text: '1 Month Ago', value: 'months_ago_1', sortable: true, width: 100 },
        { text: '2 Month Ago', value: 'months_ago_2', sortable: true, width: 100 },
        { text: '3 Month Ago', value: 'months_ago_3', sortable: true, width: 100 },
        { text: '4 Month Ago', value: 'months_ago_4', sortable: true, width: 100 },
        { text: '5 Month Ago', value: 'months_ago_5', sortable: true, width: 100 },
        { text: '6 Month Ago', value: 'months_ago_6', sortable: true, width: 100 },
        { text: '7 Month Ago', value: 'months_ago_7', sortable: true, width: 100 },
        { text: '8 Month Ago', value: 'months_ago_8', sortable: true, width: 100 },
        { text: '9 Month Ago', value: 'months_ago_9', sortable: true, width: 100 },
        { text: '10 Month Ago', value: 'months_ago_10', sortable: true, width: 100 },
        { text: '11 Month Ago', value: 'months_ago_11', sortable: true, width: 100 },
        { text: '12 Month Ago', value: 'months_ago_12', sortable: true, width: 100 },
      ],
      search: '',
      singleSelect: false,
      selected: this.items ? this.items : [],
      averages: this.avgs ? this.avgs : [],
    }
  },
  watch: {
    selected: {
      handler() {
        this.getAverages()
      },
    },
  },
  methods: {
    getAverages() {
      let totals = this.averages
      const size = this.selected.length

      for (const prop_name in this.averages) {
        totals[prop_name] = 0
      }

      this.selected.forEach(row => {
        for (const prop_name in row) {
          totals[prop_name] += Number(row[prop_name])
        }
      })

      for (const prop_name in totals) {
        totals[prop_name] = totals[prop_name] / size
      }

      this.averages = totals
    },
    getTextClass(name = '', index = 0, greater = false) {
      if(greater === false){
        if (this.comparisons[index][name] >= this.averages[name]) {
          return 'greater-than';
        } else {
          return 'less-than';
        }
      }else{
        if (this.comparisons[index][name] <= this.averages[name]) {
          return 'greater-than';
        } else {
          return 'less-than';
        }
      }
    },
  },
}
</script>

<style scoped>
.greater-than {
  color: #2ecc71;
}
.less-than {
  color: #ff4c51;
}
.cont-table {
  display: flex;
  flex-direction: column;
  margin: 30px 0px;
  gap: 20px;
}
.col-domain {
  max-width: 500px !important;
  word-break: break-all;
}
</style>
