var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"cont-table"},[_c('v-card-title',[_c('h3',[_vm._v("URL link profile")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"search":_vm.search,"item-key":"url","show-select":"","single-select":_vm.singleSelect,"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"col-domain"},[_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_vm._v(_vm._s(item.url))])])]}},{key:"item.page_inbound_links",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Number(item.page_inbound_links).toFixed(0))+" ")]}},{key:"item.page_followed_links",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Number(item.page_followed_links).toFixed(0))+" ")]}},{key:"item.page_ref_domains",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Number(item.page_ref_domains).toFixed(0))+" ")]}},{key:"item.page_ref_domains_by_total_links",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Number(item.page_ref_domains_by_total_links * 100).toFixed(2))+"% ")]}},{key:"item.page_follow_by_total_links",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Number(item.page_follow_by_total_links * 100).toFixed(2))+"% ")]}},{key:"item.page_avg_domain_rating",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Number(item.page_follow_by_total_links).toFixed(2))+" ")]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('template',{slot:"body.prepend"},[_vm._l((_vm.comparisons),function(comp,index){return _c('tr',{key:index},[_c('td'),_c('td',[_c('div',{staticClass:"col-domain"},[_c('a',{attrs:{"href":comp.url,"target":"_blank"}},[_vm._v(_vm._s(comp.url))])])]),_c('td',[_c('span',{class:_vm.getTextClass('page_inbound_links', index)},[_vm._v(" "+_vm._s(Number(comp.page_inbound_links).toFixed(0))+" ")])]),_c('td',[_c('span',{class:_vm.getTextClass('page_followed_links', index)},[_vm._v(" "+_vm._s(Number(comp.page_followed_links).toFixed(0))+" ")])]),_c('td',[_c('span',{class:_vm.getTextClass('page_ref_domains', index)},[_vm._v(_vm._s(Number(comp.page_ref_domains).toFixed(0)))])]),_c('td',[_c('span',{class:_vm.getTextClass('page_ref_domains_by_total_links', index)},[_vm._v(" "+_vm._s(Number(comp.page_ref_domains_by_total_links * 100).toFixed(2))+"% ")])]),_c('td',[_c('span',{class:_vm.getTextClass('page_follow_by_total_links', index)},[_vm._v(" "+_vm._s(Number(comp.page_follow_by_total_links * 100).toFixed(2))+"% ")])]),_c('td',[_c('span',{class:_vm.getTextClass('page_avg_domain_rating', index)},[_vm._v(" "+_vm._s(Number(comp.page_avg_domain_rating * 100).toFixed(2))+" ")])])])}),_c('tr',[_c('td'),_c('td',[_vm._v("Averages")]),_c('td',[_vm._v(_vm._s(Number(_vm.averages.page_inbound_links).toFixed(0)))]),_c('td',[_vm._v(_vm._s(Number(_vm.averages.page_followed_links).toFixed(0)))]),_c('td',[_vm._v(_vm._s(Number(_vm.averages.page_ref_domains).toFixed(0)))]),_c('td',[_vm._v(_vm._s(Number(_vm.averages.page_ref_domains_by_total_links * 100).toFixed(2))+"%")]),_c('td',[_vm._v(_vm._s(Number(_vm.averages.page_follow_by_total_links * 100).toFixed(2))+"%")]),_c('td',[_vm._v(_vm._s(Number(_vm.averages.page_avg_domain_rating * 100).toFixed(2)))])])],2)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }