<template>
  <div>
    <v-card >
    <v-card-title>
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
    </v-card-title>

    <v-data-table
      class="elevation-1"
      :headers="headers"
      :items="items"
      :loading="loading"
      :search="search"
      item-key="url"
      show-select
      v-model="selected"
      :single-select="singleSelect"
      mobile-breakpoint="0"
    >
      <template slot="body.prepend">
        <tr v-for="(comp, index) in comparisons" :key="index">
          <td></td>
          <td> {{ 'comparison' }} </td>
          <td>
            <div class="col-domain">
              <a :href="comp.url" target="_blank">{{ comp.url }}</a>
            </div>
          </td>
          <td>
            <span :class="getTextClass('backlinks', index)">
              {{ Number(comp.backlinks).toFixed(0) }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('text', index)">
              {{ Number(comp.text).toFixed(0) }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('image', index)">
              {{ Number(comp.image).toFixed(0) }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('ugc', index)">
              {{ Number(comp.ugc).toFixed(0) }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('sponsored', index)">
              {{ Number(comp.sponsored).toFixed(0) }}
            </span>
          </td>
          <td>
            <span :class="getTextClass('redirect', index)">
              {{ Number(comp.redirect).toFixed(0) }}
            </span>
          </td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td>Averages</td>
          <td>{{ Number(averages.backlinks).toFixed(2) }}</td>
          <td>{{ Number(averages.text).toFixed(2) }}</td>
          <td>{{ Number(averages.image).toFixed(2) }}</td>
          <td>{{ Number(averages.ugc).toFixed(2) }}</td>
          <td>{{ Number(averages.sponsored).toFixed(2) }}</td>
          <td>{{ Number(averages.redirect).toFixed(2) }}</td>
        </tr>
      </template>

      <template v-slot:[`item.rank`]="{ item }">
        {{ Number(item.rank).toFixed(0) }}
      </template>
      <template v-slot:[`item.url`]="{ item }">
        <div class="col-domain">
          <a :href="item.url" target="_blank">{{ item.url }}</a>
        </div>
      </template>
      <template v-slot:[`item.backlinks`]="{ item }">
        {{ Number(item.backlinks).toFixed(0) }}
      </template>
      <template v-slot:[`item.text`]="{ item }">
        {{ Number(item.text).toFixed(0) }}
      </template>
      <template v-slot:[`item.image`]="{ item }">
        {{ Number(item.image).toFixed(0) }}
      </template>
      <template v-slot:[`item.ugc`]="{ item }">
        {{ Number(item.ugc).toFixed(0) }}
      </template>
      <template v-slot:[`item.sponsored`]="{ item }">
        {{ Number(item.sponsored).toFixed(0) }}
      </template>
      <template v-slot:[`item.redirect`]="{ item }">
        {{ Number(item.redirect).toFixed(0) }}
      </template>

    </v-data-table>
  </v-card>

  </div>
</template>

<script>
export default {
  props: {
    loading: Boolean,
    items: Array,
    comparisons: Array,
    avgs: Object,
  },
  data() {
    return {
      headers: [
        { text: 'Rank', value: 'rank', sortable: true },
        { text: 'URL', value: 'url', sortable: true, width: 350 },
        { text: 'Dofollow', value: 'dofollow', sortable: true },
        { text: 'Nofollow', value: 'nofollow', sortable: true },
        { text: 'Canonical', value: 'canonical', sortable: true },
        { text: 'Gov', value: 'gov', sortable: true },
        { text: 'Edu', value: 'edu', sortable: true },
        { text: 'Rss', value: 'rss', sortable: true },
      ],
      search: '',
      singleSelect: false,
      selected: this.items ? this.items : [],
      averages: this.avgs ? this.avgs : [],
    }
  },
  watch: {
    selected: {
      handler() {
        this.getAverages()
      },
    },
  },
  methods: {
    getAverages() {
      let totals = this.averages
      const size = this.selected.length

      for (const prop_name in this.averages) {
        totals[prop_name] = 0
      }

      this.selected.forEach(row => {
        for (const prop_name in row) {
          totals[prop_name] += Number(row[prop_name])
        }
      })

      for (const prop_name in totals) {
        totals[prop_name] = totals[prop_name] / size
      }

      this.averages = totals
    },
    getTextClass(name = '', index = 0, greater = false) {
      if(greater === false){
        if (this.comparisons[index][name] >= this.averages[name]) {
          return 'greater-than';
        } else {
          return 'less-than';
        }
      }else{
        if (this.comparisons[index][name] <= this.averages[name]) {
          return 'greater-than';
        } else {
          return 'less-than';
        }
      }
    },
  },
}
</script>

<style scoped>
.greater-than {
  color: #2ecc71;
}
.less-than {
  color: #ff4c51;
}
.cont-table {
  display: flex;
  flex-direction: column;
  margin: 30px 0px;
  gap: 20px;
}
.col-domain {
  max-width: 500px !important;
  word-break: break-all;
}
</style>
