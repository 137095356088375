<template>
  <v-card class="cont-table" v-if="items.values">
    <v-card-title>
      <h3>All Anchors Aggregate Text</h3>
    </v-card-title>
    
    <div class="col-7 margin-auto">
        <pie-chart-donut
              :series="getCategories()"
              :displays="['Exact Match', 'Partial Match', 'Branded', 'Naked URL', 'Generic']"
            ></pie-chart-donut>
    </div>

    <v-card-title>
      <v-spacer></v-spacer>

      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
    </v-card-title>
    
    <v-data-table
      class="elevation-1"
      :headers="headers"
      :items="anchors"
      :loading="loading"
      :search="search"
      item-key="url"
      mobile-breakpoint="0"
    >
      <template v-slot:[`item.anchor`]="{ item }">
        <div class="col-domain">
          {{ item.anchor }}
        </div>
      </template>
      <template v-slot:[`item.count`]="{ item }">
        {{ Number(item.count).toFixed(0) }}
      </template>
      <template v-slot:[`item.percentage`]="{ item }"> {{ Number(item.percentage).toFixed(2) }}% </template>
      <template v-slot:[`item.chart`]="{ item }">
        <div :style="`background: #ff8800; width: ${ Number(item.chart).toFixed(2) }%; height: 10px;`"></div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import PieChartDonut from '../../../../components/chart/apexChart/PieChartDonut.vue';
export default {
  components: {
    PieChartDonut,
  },
  props: {
    loading: Boolean,
    items: Object,
  },
  data() {
    return {
      headers: [
        { text: 'Anchors', value: 'anchor', sortable: true, width: 300 },
        { text: 'Category', value: 'category', sortable: true, width: 200 },
        { text: 'Referring domains', value: 'count', sortable: true },
        { text: 'Percentage', value: 'percentage', sortable: true },
        { text: 'Chart', value: 'chart', sortable: true, width: 250 },
      ],
      search: '',
      anchors: [],
    }
  },
  mounted() {
    this.getAnchors();
  },
  methods: {
    getAnchors(){
      this.anchors = this.items.values.map(item => ({
        anchor: item.anchor,
        count: item.count,
        category: item.category,
        percentage: (item.count/this.items.total)*100,
        chart: (item.count/this.items.total)*100,
      }));
    },

    getCategories() {
      //['Exact', 'Partial', 'Branded', 'Naked URL', 'Generic'],
      let anchor_categories = 
      {
        exact: 0,
   
        partial: 0,
     
        branded: 0,
 
        naked_URL: 0,
     
        generic: 0,
      }
    
      this.items.values.forEach(element => {
        if (element.category == 'Exact Match') {
          anchor_categories.exact = anchor_categories.exact+1;
        } else if (element.category == 'Partial Match') {
          anchor_categories.partial++;
        } else if (element.category == 'Branded') {
          anchor_categories.branded++;
        } else if (element.category == 'Naked URL') {
          anchor_categories.naked_URL++;
        } else if (element.category == 'Generic') {
          anchor_categories.generic++;
        }
      })
      return [anchor_categories.exact, anchor_categories.partial, anchor_categories.branded, anchor_categories.naked_URL, anchor_categories.generic];
    },
 
  },
}
</script>

<style scoped>
.cont-table {
  display: flex;
  flex-direction: column;
  margin: 30px 0px;
  gap: 20px;
}
.col-domain {
  max-width: 500px !important;
  word-break: break-word;
}
</style>
